<template>
	<div>
    <v-card>
      <v-toolbar
        flat
        color="primary"
        dark
      >
        <v-toolbar-title>{{competition.name}}</v-toolbar-title>
      </v-toolbar>
      <v-tabs :vertical="!isMobilephone" v-if="mounted">
				<v-tab>
          <v-icon left>
            fa-trophy
          </v-icon>
          Verseny adatai
        </v-tab>
				<v-tab>
          <v-icon left>
            fa-user-tie
          </v-icon>
          Szervezési adatok
        </v-tab>
				<v-tab>
          <v-icon left>
            fa-dumbbell
          </v-icon>
					Gyakorlatok
        </v-tab>
				<v-tab>
					<v-icon left>
						fa-scroll
					</v-icon>
					Kiírás
				</v-tab>
        <v-tab v-if="competition.status==1">
          <v-icon left>
            fa-hand-paper
          </v-icon>
          Nevezés
        </v-tab>
				<v-tab v-if="competition.status>0&&beginnedCompetition">
          <v-icon left>
            fa-star
          </v-icon>
          A verseny állása
        </v-tab>
				<v-tab-item>
          <competitionDetails :competition="competition" :editable="false"/>
        </v-tab-item>
				<v-tab-item>
					<organizationalDetails  :editable="false" :organizers="competition.Organizer" :organizerClub="competition.OrganizerClub" :organizerRegion="competition.Region" :level="competition.level"/>
				</v-tab-item>
				<v-tab-item>
					<exercises :editable="false" :competitionStatus="competition.status" :competitionExercises="competition.ExerciseMU_Competitions" :competitionId="$route.params.id" :exercises="exercises" :exerciseMUs="exerciseMUs" :ageGroups="ageGroups" :measurementUnits="measurementUnits"/>
				</v-tab-item>
				<v-tab-item>
					<announcement :competitionId="$route.params.id" :editable="false" :visitor="true"/>
				</v-tab-item>
        <v-tab-item v-if="competition.status==1">
					<competitionApplication :endedApplicationDeadline="endedApplicationDeadline" :beginnedCompetition="beginnedCompetition" :ageGroups="ageGroups" :editable="competition.status==1" :competitionId="$route.params.id"/>
        </v-tab-item>
				<v-tab-item v-if="competition.status>0&&beginnedCompetition">
					<competitionState :competitionId="$route.params.id"/>
        </v-tab-item>

      </v-tabs>
    </v-card>
    <template>

  </template>

	</div>
</template>

<script>
	import router from '../router';
  import competitionLevelNames from '../plugins/competitionLevelNames';
  import roles_CompetitionLevels from '../plugins/roles-CompetitionLevels'
  import competitionStatusNames from '../plugins/competitionStatusNames'
	import exercises from '../components/competitionOrganizer-Exercises'
	import competitionDetails from '../components/competitionDetails'
	import competitionApplication from '../components/competitionApplication'
	import organizationalDetails from '../components/competitionOrganizer-OrganizationalDetails'
	import announcement from '../components/competition-announcement'
	import competitionState from '../components/competition-state'
	import report from '../components/competition-report'

	export default {
    props: ['id'],
		components: {
			exercises,
			competitionDetails,
			competitionApplication,
			organizationalDetails,
			announcement,
			competitionState,
			report
		},
		data: function(){
			return {
				mounted: false,
        competitionLevelNames,
        competitionStatusNames,
        competition: {},
				exerciseMUs: [],
				measurementUnits: [],
				exercises: [],
				ageGroups: [],
        regions: [],
        dialogs: {
          editDetail: {
						changedProperty: '',
						changedValue: '',
					},
					confirm: {
						show: false,
						text: '',
						title: '',
						confirmedMethod: () => {},
						confirmButton: '',
						cancelButton: ''
					}
        },
      }
		},
		computed: {
			endedApplicationDeadline(){
				return (new Date())>this.dateParse(this.competition.applicationDeadline)
			},
			beginnedCompetition() {
				return (new Date())>this.dateParse(this.competition.date)

			}
		},
		methods: {

		},
		mounted(){
			this.axios({url: "competition/"+this.$route.params.id, params: {infoForApplication: true}, method: "GET"}).then((response) => {
        if(response.data.success) {
          this.competition=response.data.data.competition;
					this.exercises = response.data.data.exercises;
					this.exerciseMUs=response.data.data.exerciseMUs;
					this.measurementUnits=response.data.data.measurementUnits;
					this.ageGroups=response.data.data.ageGroups;
					this.mounted=true;

        }
			})
		}
	}
</script>
