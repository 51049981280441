<template>
  <div>
    <v-tooltip top v-if="editable&&!endedApplicationDeadline">
      Csapat nevezése
      <template v-slot:activator="{on, attrs}">
        <v-btn fab v-on="on" v-bind="attrs" @click="applyTeamDialog" color="success"><v-icon>fa-plus</v-icon></v-btn>
      </template>
    </v-tooltip>
    <h1>Nevezett csapataim</h1>
    <appliedTeams :canDeleteTeam="editable&&!beginnedCompetition" :ageGroups="ageGroups" :competitionId="competitionId" :editable="editable&&!beginnedCompetition" :appliedTeams="myAppliedTeams"/>
    <appliedTeamDetail @change="detailDialog.show=false" @newTeam="addTeam" :ageGroups="ageGroups" :competitionId="competitionId" :team="detailDialog.team" @close="detailDialog.show=false" :show="detailDialog.show" :newTeam="detailDialog.new" :editable="detailDialog.editable"/>
  </div>
</template>

<script>
  import appliedTeams from './competitionApplication-appliedTeams'
  import appliedTeamDetail from './competitionApplication-appliedTeamDetail'
export default {
  props: ['competitionId', 'editable', 'ageGroups', 'beginnedCompetition', 'endedApplicationDeadline'],
  components: {
    appliedTeams,
    appliedTeamDetail
  },
  data: function(){
    return {
      myAppliedTeams: [],
      detailDialog: {
        show: false,
        new: true,
        teamId: null,
        editable: true,
        team: {
          name: "",
          Coaches: [],
          KidAthletes: [],
          AgeGroupId: null
        },

      }
    }
  },
  computed: {

  },
  watch: {

  },
  methods: {
    addTeam: function() {
      this.myAppliedTeams.push(this.detailDialog.team)
    },
    applyTeamDialog: function() {
      this.detailDialog= {
        show: true,
        new: true,
        teamId: null,
        editable: true,
        team: {
          name: "",
          Coaches: [],
          KidAthletes: [],
          AgeGroupId: null
        },
      }
    },
    getAppliedTeams: function() {
      this.axios({url: `/competition/${this.competitionId}/myAppliedTeams`}).then(response => {
        if(response.data.success) this.myAppliedTeams = response.data.data.Teams
      })
    }
  },
  mounted() {
    this.getAppliedTeams();
  }
}


</script>
